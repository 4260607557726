import * as React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import favicon from '../images/favicon.ico';

const NotFoundPage = () => {
  return (
    <main>
      <Helmet>
        <title>Pagina niet gevonden | Barometer samenleven</title>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <section className="vl-region">
        <div className="vl-layout">
          <h1 className="vl-title vl-title--h1">Pagina niet gevonden</h1>
          <p className="vl-introduction vl-u-spacer-bottom">
            Deze pagina kon niet gevonden worden.
          </p>
          <Link to="/">Ga naar de startpagina</Link>.
        </div>
      </section>
    </main>
  );
};

export default NotFoundPage;
